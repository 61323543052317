import Modal from "./Modal";

const InitialModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="bg-black text-white drop-shadow-2xl font-gryphius-mvb text-2xl p-12 max-w-2xl text-center rounded-lg relative ">
        <img
          className="cursor-pointer absolute top-2 right-2 z-30 !pointer-events-auto"
          alt="close"
          src="/images/x.png"
          onClick={onClose}
        />
        <div className="overflow-y-auto max-h-full">
          <h1 className="text-6xl">COIL CITY</h1>
          <p style={{ fontVariant: "small-caps" }}>
            Nothern administrative division of greater sutland
          </p>
          <p className="italic mt-12">
            Following the events of Dolos, a sentient entity escaped from the
            city and took refuge in the mycelium network of the forest.
            <br />
            Many people have been reported missing, only to be found wandering
            back from the woods injured, murmuring of a “metamorphosis”.
            <br />A cult has been linked with these people; from what little is
            known about their leader, he is known to go by the name of “Puckle”.
          </p>
          <p style={{ fontVariant: "small-caps" }} className="mt-12">
            YOU ARE WELCOME TO CLICK AND DRAG TO EXPLORE THIS MAP. AREAS OF
            INTEREST HAVE BEEN HIGHLIGHTED.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default InitialModal;
