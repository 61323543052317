import { Building } from "../data/buildings";

export const updateAt = <T>(xs: T[], index: number, f: (x: T) => T) => {
  const newX = f(xs[index]);
  const newXs = [...xs.slice(0, index), newX, ...xs.slice(index + 1)];
  return newXs;
};

export const isInBoundingBox = (
  hover: Building,
  x: number,
  y: number
): boolean => {
  const cushion = 30;
  if (hover.negativeBoxes !== undefined) {
    for (let i = 0; i < hover.negativeBoxes.length; i++) {
      const box = hover.negativeBoxes[i];
      if (
        x >= box.left &&
        x <= box.left + box.width &&
        y >= box.top &&
        y <= box.top + box.height
      )
        return false;
    }
  }

  return (
    x - cushion >= hover.left &&
    x + cushion <= hover.left + hover.width &&
    y - cushion >= hover.top &&
    y + cushion <= hover.top + hover.height
  );
};
