import React from "react";
import {
  Card,
  CardBody,
  CardLink,
  CardLinks,
  CardPolaroid,
  CardText,
} from "./Card";

export const communeImage = "/images/polaroids/commune.png";

const CommuneCard = (props: { closeCard: () => void }) => {
  return (
    <Card closeCard={props.closeCard}>
      <CardPolaroid src={communeImage} />
      <CardBody dropdownHeight={600}>
        <CardText>
          <p>
            Carved into the husk of a decommissioned cruiseliner stands a
            church.
          </p>
          <p>
            Stained glass depictions of insects consumed by fungus casts a
            prismatic light on the rusted metal.
          </p>
          <p>
            You can still read the original slogan corroded into the ships
            hull... “EXPLORE AND ADORE”
          </p>
        </CardText>
        <CardLinks>
          <CardLink
            text="DISCORD INVITE"
            href="https://discord.gg/jtfdJ48uEY"
          />
        </CardLinks>
      </CardBody>
    </Card>
  );
};

export default CommuneCard;
