import { useEffect, useState } from "react";
import "./App.css";
import Map from "./components/Map";

const loadImage = async (src: string) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      resolve(true);
    };
  });
};

const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const WaitForImage = ({
  imageSrc,
  LoadingComponent,
  LoadedComponent,
}: {
  imageSrc: string;
  LoadingComponent: React.FC;
  LoadedComponent: React.FC;
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    Promise.all([loadImage(imageSrc), sleep(3000)]).then(() => {
      setHasLoaded(true);
    });
  }, [imageSrc]);
  if (!hasLoaded) {
    return <LoadingComponent />;
  } else {
    return <LoadedComponent />;
  }
};

const LoadingScreen = () => {
  return (
    <div className="w-screen h-screen font-gryphius-mvb flex flex-col items-center justify-center bg-black text-white">
      <div>
        <img alt="Loading" className="" src="images/loading.gif" />
      </div>
      <div className="mt-8 text-4xl">LOADING</div>
    </div>
  );
};

function App() {
  return (
    <WaitForImage
      imageSrc={"images/coil_map.jpg"}
      LoadingComponent={LoadingScreen}
      LoadedComponent={Map}
    />
  );
}

export default App;
