import React, { PropsWithChildren } from "react";

function Modal(
  props: PropsWithChildren & {
    isOpen: boolean;
    onClose: () => void;
  }
) {
  const { isOpen, onClose, children } = props;

  if (!isOpen) return null;

  return (
    <div
      className="fixed left-0 top-0 right-0 bottom-0 flex items-center justify-center z-20 bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div className="left-0 top-0 right-0 bottom-0"></div>
      <div
        style={contentAnimation}
        className="p-0 max-w-90 max-h-90 flex column"
      >
        {children}
      </div>
    </div>
  );
}

const contentAnimation = {
  animation: "grow 0.5s forwards",
};

export default Modal;
