import React, { useEffect, useRef, useState } from "react";
import {
  ReactZoomPanPinchContentRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";
import { Building, initialBuildings } from "../data/buildings";
import { updateAt } from "../utils";
import HoverableBuilding from "./HoverableBuilding";
import InitialModal from "./InitialModal";

const Map = () => {
  const [showInitialModal, setShowInitialModel] = useState<boolean>(true);
  const [buildings, setBuildings] = useState<Building[]>(initialBuildings);
  const [isPanning, setIsPanning] = useState<boolean>(false);

  //preload images
  useEffect(() => {
    buildings.forEach((building) => {
      const img = new Image();
      img.src = building.card.polaroidPreloadImage;
    });
  }, [buildings]);
  // const setAllCardsVisible = (visible: boolean) => {
  //   const newHovers = buildings.map((hover) => {
  //     return {
  //       ...hover,
  //       card: {
  //         ...hover.card,
  //         visible: visible,
  //       },
  //     };
  //   });
  //   setBuildings(newHovers);
  // };

  const setCardVisible = (index: number, visible: boolean) => {
    const newHovers = updateAt(buildings, index, (hover) => {
      return { ...hover, card: { ...hover.card, visible: visible } };
    });
    setBuildings(newHovers);
  };
  const containerRef = useRef<HTMLDivElement | null>(null);
  const transformComponentRef = useRef<ReactZoomPanPinchContentRef | null>(
    null
  );
  // const handleMouseMove = (event: React.MouseEvent) => {
  //   if (transformComponentRef.current === null) {
  //     return;
  //   }
  //   const state = transformComponentRef.current.instance.transformState;
  //   const mouseX = event.clientX;
  //   const mouseY = event.clientY;
  //   const imageX = state.positionX;
  //   const imageY = state.positionY;
  //   const zoomScale = state.scale;
  //
  //   // the x/y on the original image
  //   const originalMouseX = (mouseX - imageX) / zoomScale;
  //   const originalMouseY = (mouseY - imageY) / zoomScale;
  //
  //   const newHovers = hovers.map((hover) => {
  //     if (isInBoundingBox(hover, originalMouseX, originalMouseY)) {
  //       return {
  //         ...hover,
  //         hovered: true,
  //       };
  //     } else {
  //       return {
  //         ...hover,
  //         hovered: false,
  //       };
  //     }
  //   });
  //   setHovers(newHovers);
  // };
  const moveTo = (x: number, y: number) => {
    if (transformComponentRef.current !== null) {
      console.log(`Moving to ${x},${y}`);
      const scale = transformComponentRef.current.instance.transformState.scale;
      transformComponentRef.current.setTransform(x, y, scale, 300, "easeOut");
    }
  };
  const moveToElementId = (id: string) => {
    setTimeout(() => {
      if (transformComponentRef.current !== null) {
        console.log(`Moving to ${id}`);
        transformComponentRef.current.zoomToElement(
          id,
          // always zoom out
          initialScale,
          300,
          "easeOut"
        );
      }
    }, 200);
  };

  const minScale = 0.5;
  const initialScale = minScale;
  return (
    <>
      <InitialModal
        isOpen={showInitialModal}
        onClose={() => setShowInitialModel(false)}
      />
      <div
        // onMouseMove={handleMouseMove}
        onClick={(event) => {
          if (event.target === containerRef.current) {
            // setAllCardsVisible(false);
          }
        }}
      >
        <TransformWrapper
          initialScale={initialScale}
          // initialPositionX={100}
          // initialPositionY={100}
          disablePadding={true}
          maxScale={6}
          minScale={minScale}
          ref={transformComponentRef}
          wheel={{
            step: 0.1,
          }}
          panning={{
            // not sure about this
            velocityDisabled: true,
            // stops the weird jumping if you click on it
            // whilst in a 'velocity' state
            // excluded: ["hoverImage"],
            excluded: ["popup-card", "p"],
          }}
          doubleClick={{ disabled: true }}
          centerOnInit={true}
          onPanningStart={(e) => {
            console.log("panning starts...", e);
            setIsPanning(true);
          }}
          onPanningStop={(e) => {
            console.log("panning stops...", e);
            setIsPanning(false);
          }}
          onTransformed={(e) => {
            console.log("transformed", e);
          }}
          customTransform={(x: number, y: number, scale: number): string => {
            // this slightly smoothes scrolling on safari
            return `translate3d(${x}px, ${y}px, 0) scale(${scale})`;
          }}
        >
          <TransformComponent
            wrapperStyle={{
              width: "100vw",
              height: "100vh",
            }}
          >
            <div
              ref={containerRef}
              style={{
                position: "relative",
                width: "4000px",
                height: "4000px",
              }}
            >
              <img src="images/coil_map.jpg" alt="Coil map" />
              <img
                src="images/coil_city_overlay.png"
                alt="Coil City"
                style={{
                  position: "absolute",
                  top: 75,
                  left: 2440,
                  zIndex: 2,
                }}
              />
              {buildings.map((building, index) => {
                return (
                  <HoverableBuilding
                    hover={building}
                    index={index}
                    isPanning={isPanning}
                    setCardVisible={setCardVisible}
                    moveTo={moveTo}
                    moveToElementId={moveToElementId}
                  />
                );
              })}
            </div>
          </TransformComponent>
        </TransformWrapper>
      </div>
    </>
  );
};

export default Map;
