import React from "react";
import {
  Card,
  CardBody,
  CardLink,
  CardLinks,
  CardPolaroid,
  CardText,
} from "./Card";

export const contactImage = "/images/polaroids/contact.png";

const ContactCard = (props: { closeCard: () => void }) => {
  return (
    <Card closeCard={props.closeCard}>
      <CardPolaroid src={contactImage} />
      <CardBody dropdownHeight={600}>
        <CardText>
          <p>
            Large vats filled with orange oil hang up on the ship’s rafters. It
            seems like the same liquid that fills the large circular pool
            embedded into the floor of the ship.
          </p>
          <p>
            Art dedicated to Puckle drapes the walls over almost every inch of
            the ship. A vacuous shrine of himself for his adoring followers.
          </p>
        </CardText>
        <CardLinks>
          <CardLink text="TWITTER" href="https://twitter.com/DJMURLO" />
          <CardLink text="INSTAGRAM" href="https://www.instagram.com/murlo/" />
        </CardLinks>
      </CardBody>
    </Card>
  );
};

export default ContactCard;
