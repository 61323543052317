import React from "react";
import { Card, CardBody, CardLink, CardLinks, CardPolaroid } from "./Card";

export const pucklePresaveImage = "/images/polaroids/puckle_presave.png";

const PucklePresaveCard = (props: { closeCard: () => void }) => {
  return (
    <Card closeCard={props.closeCard}>
      <CardPolaroid src={pucklePresaveImage} />
      <CardBody dropdownHeight={300}>
        <CardLinks>
          <CardLink text="PUCKLE" href="https://coil.sup.nr/Murlo" />
        </CardLinks>
      </CardBody>
    </Card>
  );
};

export default PucklePresaveCard;
