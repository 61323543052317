import React from "react";
import {
  Card,
  CardBody,
  CardLink,
  CardLinks,
  CardPolaroid,
  CardText,
} from "./Card";

export const coilShopImage = "/images/polaroids/coil_shop.png";

const CoilshopCard = (props: { closeCard: () => void }) => {
  return (
    <Card closeCard={props.closeCard}>
      <CardPolaroid src={coilShopImage} />
      <CardBody dropdownHeight={600}>
        <CardText>
          <p>
            Often found to be documenting a lot of local folklore and can be
            used as a source of knowledge on the recent events in the city.
          </p>
          <p>
            “Coil Shop”, not exactly the most original name for a store but what
            the owner lacked in imagination was made up for in a masochistic
            passion for a life in the arts.
          </p>
          <p>
            “Maybe one more album” the owner keeps saying, as the bloated
            industry begins to eat itself.
          </p>
        </CardText>
        <CardLinks>
          <CardLink text="COIL SHOP" href="https://djmurlo.bandcamp.com" />
        </CardLinks>
      </CardBody>
    </Card>
  );
};

export default CoilshopCard;
