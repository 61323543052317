import React from "react";
import {
  Card,
  CardBody,
  CardLink,
  CardLinks,
  CardPolaroid,
  CardText,
} from "./Card";

export const homeVideosImage = "/images/polaroids/home_videos.png";

const HomeVideosCard = (props: { closeCard: () => void }) => {
  return (
    <Card closeCard={props.closeCard}>
      <CardPolaroid src={homeVideosImage} />
      <CardBody dropdownHeight={600}>
        <CardText>
          <p>
            The neighbours eventually noticed the pile of unopened post stacking
            up against the door.
          </p>
          <p>The landlord kicked it open to the stench of rotting food.</p>
          <p>
            Amongst the unfinished meal and piles of newspapers was left a hard
            drive titled “Home Videos”.
          </p>
        </CardText>
        <CardLinks>
          <CardLink
            text="YOUTUBE PLAYLIST"
            href="https://www.youtube.com/playlist?list=PLoXiXlAslzIBzbUcOtJS0u6Vx2ulKAo42"
          />
        </CardLinks>
      </CardBody>
    </Card>
  );
};

export default HomeVideosCard;
