import { Card as CardType } from "../data/buildings";

const Card = ({
  id,
  card,
  originalLeft,
  originalTop,
  visible,
  closeCard,
}: {
  id: string;
  card: CardType;
  originalLeft: number;
  originalTop: number;
  visible: boolean;
  closeCard: () => void;
  moveTo: (x: number, y: number) => void;
}) => {
  return visible ? (
    <div
      id={id}
      className="card"
      style={{
        position: "absolute",
        left: originalLeft + card.offsetLeft,
        top: originalTop + card.offsetTop,
        zIndex: 2,
        animation: "grow 0.5s forwards",
      }}
    >
      <card.component closeCard={closeCard} />
    </div>
  ) : null;
};

export default Card;
