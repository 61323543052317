import React from "react";
import {
  Card,
  CardBody,
  CardLink,
  CardLinks,
  CardPolaroid,
  CardText,
} from "./Card";

export const idolsImage = "/images/polaroids/idols.png";

const IdolsCard = (props: { closeCard: () => void }) => {
  return (
    <Card closeCard={props.closeCard}>
      <CardPolaroid src={idolsImage} />
      <CardBody dropdownHeight={600}>
        <CardText>
          <p>
            The hospital began receiving critically injured patients from the
            forest.
          </p>
          <p>
            Around the same time, word came about that a man selling figurines
            at the edge of town boasted about how he was responsible for the
            injuries, something about how he could “turn people into his
            butterflies”.
          </p>
          <p>He fled before he could be questioned.</p>
        </CardText>
        <CardLinks>
          <CardLink
            text="PUCKLE's MINIATURES"
            href="https://store.murlo.tv/release/406823-murlo-full-model-set-compendium-bundle"
          />
        </CardLinks>
      </CardBody>
    </Card>
  );
};

export default IdolsCard;
