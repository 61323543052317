import { Building } from "../data/buildings";
import Card from "./Card";

const HoverableBuilding = ({
  hover,
  index,
  isPanning,
  setCardVisible,
  moveTo,
  moveToElementId,
}: {
  hover: Building;
  index: number;
  isPanning: boolean;
  setCardVisible: (index: number, visible: boolean) => void;
  moveTo: (x: number, y: number) => void;
  moveToElementId: (id: string) => void;
}) => {
  const style = {
    width: hover.width,
    height: hover.height,
    // cursor: "pointer",
    // opacity: hover.hovered || hover.card.visible ? 1 : 0,
    left: hover.left,
    top: hover.top,
    // backgroundColor: "red",
    zIndex: 2,
  };
  const cardId = `card-${index}`;
  return (
    <>
      <div
        key={index}
        // className="hoverImage"
        onClick={(e) => {
          console.log("click event", e);
          setCardVisible(index, !hover.card.visible);
          moveToElementId(cardId);
        }}
        className="popup-card absolute cursor-pointer"
        style={style}
      ></div>
      <Card
        id={cardId}
        card={hover.card}
        originalLeft={hover.left}
        originalTop={hover.top}
        visible={hover.card.visible}
        moveTo={moveTo}
        closeCard={() => setCardVisible(index, false)}
      />
    </>
  );
};

export default HoverableBuilding;
