import { CardProps } from "../components/cards/Card";
import CoilshopCard, { coilShopImage } from "../components/cards/Coilshop";
import CommuneCard, { communeImage } from "../components/cards/Commune";
import ContactCard, { contactImage } from "../components/cards/Contact";
import CuratedExperienceCard, {
  curatedExperiencesImage,
} from "../components/cards/CuratedExperiences";
import HomeVideosCard, {
  homeVideosImage,
} from "../components/cards/HomeVideos";
import IdolsCard, { idolsImage } from "../components/cards/Idols";
import PucklPresaveCard, {
  pucklePresaveImage,
} from "../components/cards/PucklePresave";

export type Card = {
  polaroidPreloadImage: string;
  visible: boolean;
  offsetLeft: number;
  offsetTop: number;
  component: React.FC<CardProps>;
};

type Box = {
  top: number;
  left: number;
  width: number;
  height: number;
};
export type Building = {
  name: string;
  top: number;
  left: number;
  width: number;
  height: number;
  negativeBoxes?: Box[];

  hovered: boolean;

  card: Card;
};
export const initialBuildings: Building[] = [
  {
    name: "coil_shop",
    left: 509,
    top: 2440,
    width: 165,
    height: 166,
    hovered: true,
    card: {
      polaroidPreloadImage: coilShopImage,
      visible: false,
      offsetLeft: -139,
      offsetTop: -1205,
      component: CoilshopCard,
    },
  },
  {
    name: "puckle_presave",
    left: 1625,
    top: 303,
    width: 386,
    height: 349,
    hovered: true,
    card: {
      polaroidPreloadImage: pucklePresaveImage,
      visible: false,
      offsetLeft: 425,
      offsetTop: 20,
      component: PucklPresaveCard,
    },
  },
  {
    name: "curated_experiences",
    left: 2961,
    top: 1068,
    width: 147,
    height: 139,
    hovered: true,
    card: {
      polaroidPreloadImage: curatedExperiencesImage,
      visible: false,
      offsetLeft: 200,
      offsetTop: -713,
      component: CuratedExperienceCard,
    },
  },
  {
    name: "idols",
    left: 1296,
    top: 919,
    width: 193,
    height: 176,
    hovered: true,
    card: {
      polaroidPreloadImage: idolsImage,
      visible: false,
      offsetLeft: -546,
      offsetTop: -844,
      component: IdolsCard,
    },
  },
  {
    name: "commune",
    left: 3033,
    top: 2107,
    width: 535,
    height: 506,
    hovered: true,
    negativeBoxes: [
      {
        left: 3380,
        top: 2300,
        width: 400,
        height: 400,
      },
    ],
    card: {
      polaroidPreloadImage: communeImage,
      visible: false,
      offsetLeft: -460,
      offsetTop: -420,
      component: CommuneCard,
    },
  },
  {
    name: "contact",
    left: 3314,
    top: 2084,
    width: 486,
    height: 465,
    hovered: true,
    card: {
      polaroidPreloadImage: contactImage,
      visible: false,
      offsetLeft: 0,
      offsetTop: 490,
      component: ContactCard,
    },
  },
  {
    name: "home_videos",
    left: 1017,
    top: 2886,
    width: 177,
    height: 232,
    hovered: true,
    card: {
      polaroidPreloadImage: homeVideosImage,
      visible: false,
      offsetLeft: 170,
      offsetTop: -340,
      component: HomeVideosCard,
    },
  },
];
