import React from "react";

export type CardProps = {
  closeCard: () => void;
};

export const Card = (props: CardProps & { children: React.ReactNode }) => {
  return (
    <div className="popup-card w-128 font-gryphius-mvb italic relative">
      <CardX onClick={props.closeCard} />
      {props.children}
    </div>
  );
};

const CardX = ({ onClick }: { onClick: () => void }) => {
  return (
    <img
      className="cursor-pointer absolute -top-2 -right-4 z-30 !pointer-events-auto"
      alt="close"
      src="/images/x.png"
      onClick={onClick}
    />
  );
};

export const CardPolaroid = (props: { src: string }) => {
  return (
    <div className="popup-card">
      <img
        alt="polaroid"
        className="max-w-full z-20 relative drop-shadow-2xl"
        src={props.src}
      />
    </div>
  );
};

type DropdownHeight = 600 | 300;

export const CardBody = (props: {
  dropdownHeight: DropdownHeight;
  children: React.ReactNode;
}) => {
  return (
    <div
      className="popup-card bg-black text-white text-center p-8 z-10 relative rounded-lg text-4xl drop-shadow-2xl ml-2"
      style={{ animation: `dropDown${props.dropdownHeight} 1s forwards` }}
    >
      {props.children}
    </div>
  );
};

export const CardText = (props: { children: React.ReactNode }) => {
  return <div className="popup-card mb-4">{props.children}</div>;
};

export const CardLinks = (props: { children: React.ReactNode }) => {
  return (
    <div className="popup-card flex gap-8 justify-center">{props.children}</div>
  );
};

export const CardLink = (props: { text: string; href: string }) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      className="popup-card underline"
      href={props.href}
    >
      {props.text}
    </a>
  );
};
