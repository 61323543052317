import React from "react";
import {
  Card,
  CardBody,
  CardLink,
  CardLinks,
  CardPolaroid,
  CardText,
} from "./Card";

export const curatedExperiencesImage =
  "/images/polaroids/curated_experiences.png";

const CuratedExperienceCard = (props: { closeCard: () => void }) => {
  return (
    <Card closeCard={props.closeCard}>
      <CardPolaroid src={curatedExperiencesImage} />
      <CardBody dropdownHeight={600}>
        <CardText>
          <p>
            In a dank old office building machinery assumed to be used by the
            cult was left rusting away. Some kind of device covered in tubes
            that stank of fungal rot.
          </p>
          <p className="mt-8">
            The filing cabinets were crammed with drawings, mostly ineligable
            scribbles, each one however annotated with various songs. A sign on
            the cabinets reads “Divine Inspiration”
          </p>
        </CardText>
        <CardLinks>
          <CardLink
            text="SPOTIFY PLAYLIST"
            href="https://open.spotify.com/playlist/6jaZYxW3vwmxNmRO2TS9t6?si=8e553bdb680d4750"
          />
        </CardLinks>
      </CardBody>
    </Card>
  );
};

export default CuratedExperienceCard;
